import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@input-output-hk/front-end-core-components/components/Link'
import Layout from '../components/Layout'
import Container from '../components/Container'
import IndexPageQuery from '../queries/IndexPageQuery'
// import logo from '../../resources/images/q-cardano_animated.svg'
import Animatedq from '../components/Animatedq'

const CTAGroup = styled.div`
  display: flex;
  margin-top: 5rem;
  margin-bottom: 10rem;

  > div {
    padding: 2rem;
    flex: 1;
    display: flex;
    justify-content: flex-end;



    &:last-of-type {
      justify-content: flex-start;
    }

    @media screen and (max-width: 768px) {
      justify-content: center!important;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    flex-direction: column;
  }
`
const Hero = styled(Box)`
  background: ${({ theme }) => theme.palette.background.secondaryGrad};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding-top: 3.5rem;
`

// const BigLogo = styled.img`
//   margin-top: 8rem;
// `

export default () => (
  <IndexPageQuery
    render={(content) => (
      <Layout>
        <Hero>
          <Container>
            <Box textAlign='center'>
              <Animatedq />
              <h1>{content.title}</h1>
              <p>{content.subtitle}</p>
            </Box>
            <CTAGroup>
              <div>
                <div>
                  <Button
                    href='/introduction/motivation/'
                    component={Link}
                    color='accentColour'
                    variant='contained'
                  >
                    {content.cta1}
                  </Button>
                </div>
              </div>
              <div>
                <div>
                  <Button
                    href='https://cardano.org'
                    component={Link}
                    color='accentColour'
                    variant='contained'
                  >
                    {content.cta2}
                  </Button>
                </div>
              </div>
            </CTAGroup>
          </Container>
        </Hero>
      </Layout>
    )}
  />
)
